const Mapping = {
  Prelem_001: "WebsiteIntroduction",
  Prelem_002: "ProductSummaryViaImage",
  Prelem_097: "ProductSummaryViaVideo",
  Prelem_004: "Sponsor",
  Prelem_005: "Quote",
  Prelem_006: "ArticleDetail",
  Prelem_007: "ContactUs",
  Prelem_008: "AnimationOnPageScroll",
  Prelem_009: "WebsiteIntroduction2",
  Prelem_010: "dynamicTweets",
  Prelem_011: "SignBoard",
  Prelem_012: "Testimonial",
  Prelem_013: "ServiceCard",
  Prelem_014: "ProductSummaryViaVideo2",
  Prelem_015: "ImageAndVideoGallery",
  Prelem_016: "ServiceShowcase",
  Prelem_017: "ProductSummaryViaImage2",
  Prelem_018: "ExpertiseShowcase",
  Prelem_019: "FullWidthImage",
  Prelem_020: "FullWidthVideo",
  Prelem_021: "ImageCrousel1",
  Prelem_022: "Banner1",
  Prelem_023: "Description",
  Prelem_024: "Banner2",
  Prelem_025: "Banner3",
  Prelem_026: "FullWidthText",
  Prelem_027: "ImageCards",
  Prelem_028: "VideoBanner1",
  Prelem_029: "FullWidthTextWithImage",
  Prelem_030: "ServiceCard1",
  Prelem_031: "ServiceCard2",
  Prelem_032: "ServiceCard3",
  Prelem_033: "FeatureBox1",
  Prelem_034: "FeatureBox2",
  Prelem_036: "ServiceCard4",
  Prelem_037: "ServiceCard5",
  Prelem_038: "Services1",
  Prelem_039: "Services2",
  Prelem_040: "Gallery1",
  Prelem_041: "MultiSlot",
  Prelem_042: "ServiceCard6",
  Prelem_043: "VideoBanner2",
  Prelem_044: "Gallery2",
  Prelem_045: "AboutUs2",
  Prelem_046: "Faq1",
  Prelem_047: "Awards1",
  Prelem_048: "DynamicPrelem",
  Prelem_050: "CustomerTestimonial2",
  Prelem_052: "ImageVideoCarousel1",
  Prelem_053: "BlogTiles",
  Prelem_054: "AboutUsThree",
  Prelem_055: "ParagraphWithHeadlineAndCTA",
  Prelem_056: "ParagraphWithHeadline",
  Prelem_057: "Banner4",
  Prelem_058: "LeftAlignParagraphWithHeadline",
  Prelem_059: "WebsiteSummaryWithSubHeading",
  Prelem_060: "WebsiteSummaryWithSubHeading2",
  Prelem_061: "ProductSummary3",
  Prelem_062: "TeamMembers",
  Prelem_063: "Statistics",
  Prelem_064: "AboutUsFourWithSubHeading",
  Prelem_065: "ServiceShowcase2",
  Prelem_066: "EcomProduct",
  Prelem_067: "ServiceCard7",
  Prelem_068: "ContentDisplayWithCategories",
  Prelem_069: "FeatureTiles",
  Prelem_070: "CounterNumberShowcase",
  Prelem_071: "MultiSlot2",
  Prelem_072: "Ecommerce3Slot1",
  Prelem_073: "Ecommerce3Slot2",
  Prelem_074: "DynamicEcommercePrelem",
  Prelem_075: "Banner5",
  Prelem_076: "ContactUsForm",
  Prelem_077: "DynamicPrelemOne",
  Prelem_078: "LearningList",
  Prelem_079: "Banner6",
  Prelem_080: "DynamicPrelemWithCarousel2",
  Prelem_081: "DynamicPrelemWithCarousel1",
  Prelem_083: "FullWidthBanner3",
  Prelem_084: "WebsiteIntroduction4",
  Prelem_085: "InfoBox",
  Prelem_090: "ZaggEcommerce",
  Prelem_091: "RegisteredProduct",
  Prelem_092: "ProductRegistration",
  Prelem_0100: "MyStory",
  Prelem_0101: "CategoryBox",
  Prelem_0102: "FeatureInfoCards",
  Prelem_105: "FormCreator",
  Prelem_0113: "SchemaAuthoringServiceCard",
  Prelem_3700: "LivestreamFeed",
  //ContentType
  ContentType_01: "Article",
  ContentType_02: "VideoLandingPage",
  ContentType_03: "Quiz",
  ContentType_04: "Poll",
  ContentType_05: "EventLandingPage",
  profile: "PlayerDetail",
};

export default Mapping;
